.home-card-grid {
  justify-content: center;
  padding: 108px 30px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 407px));
  grid-gap: 50px 40px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
